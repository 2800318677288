@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Inter', sans-serif;
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #49525a;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.label {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  transform: translate(-10px, -50%);
}



.Hotspot {
  cursor: pointer;
  width: 16px;
  height: 16px;
  border-radius: 20px;
  background-color: white;
  border: 2px solid white;
  position: relative;
  animation: pulse 2s infinite;
  transition: width 0.2s, height 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Hotspot.hide {
  opacity: 0.2;
  pointer-events: none;
  width: 6px;
  height: 6px;
}

.Hotspot::after {
  content: '+';
  font-size: 16px;
  color: black;
}

.Hotspot.open::after {
  content: '-';
  font-size: 16px;
}

.lable-content {
  background-color: #00000050;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  left: 20px;
  width: 200px;
  color: white;
}

.lable-content.hide {
  display: none !important;
}

@keyframes pulse {
  from {
    box-shadow: 0px 0px 0px 0px rgb(255, 255, 255);
  }

  80% {
    box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0);
  }

  to {
    box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0);
  }
}